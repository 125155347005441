import React from 'react';
import RentalCard from 'components/rental/RentalCard';
import { connect } from 'react-redux';
import { fetchRentals } from 'actions'

class RentalHome extends React.Component {

    componentDidMount() {
        this.props.dispatch(fetchRentals());
    }

    renderRentals = (rentals) =>
        rentals.map(rental =>
            <div key={rental._id} className="col-md-3">
                <RentalCard rental={rental} />
            </div>
        );

    render() {
        const { rentals } = this.props;
        return (
            <div className="card-list">
                <h1 className="page-title">Available Properties</h1>
                <div className="row">
                    {this.renderRentals(rentals)}
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({rentals}) => {
    return {
        rentals: rentals.items
    };
};

export default connect(mapStateToProps)(RentalHome);